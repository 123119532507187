import React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';   
import "./AppFooterStyle.css"
function appFooter  ()  {
    return (
      
            <footer className='p-4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6 text-left'>
                            nazwa Sp. z o.o.<br/>
                            ul. ulica 44<br/>
                            33-100 Tarnów<br/>
                            NIP: 873-333-333-33<br/>
                        </div>
                        <div className='col-6 text-right d-flex flex-column align-items-end'>
                            <a href='mailto:kontakt@.pl'>kontakt@domena.pl</a><br/>
                            <a href='tel:0000000000'>tel: +48 000 000 000</a>
                        </div>
                    </div>
                </div>
            </footer>
 
    );
};

export default appFooter;