import React , { useContext } from 'react';
import "./AppHeaderStyle.css";
import logo from "../../../src/img/logo.png";

const appHeader = () => {
    return (
            <header className='p-4'>
                <div className='container'>
                    <div className='row'>
                            <div className='col-6 text-left'>
                                <img src={logo} />
                            </div>
                        <div className='col-6 d-flex justify-content-end align-items-center'>
                            <a href="tel:000000000" class="thm-btn cta-three__btn">Zadzwoń</a>
                        </div>
                    </div>
                </div>
         </header>
    );
};

export default appHeader;