import React, { Component } from "react";
import { Link } from 'react-router-dom';

class FormBuisnessComponent extends Component {
  state = {
    partner: false,
    isSubmitted: false,
    rangeValue: 50,
    isValidate: false,
    
    errors:{
      CustomerAge:false,
      CustomerPartnerAge:false,
      tel:false,
      email:false,
    }
  };

  messages ={
    tel_incorect: 'Błedny numer telefonu',
    email_Incorect: 'Brak @ w E-mailu',
    age_incorect: 'Podano błędny wiek 1-120'
  }

  handlePartner = () => {
    this.setState({ partner: !this.state.partner });
  };

  handleRangeChange = (event) => {
    this.setState({ rangeValue: event.target.value });
  };

  submit(e) {
    e.preventDefault();
    const formElements = document.querySelector("form");
    const formData = new FormData(formElements);

    const validation = this.formValidation();

    if (validation == true) {
      fetch(
        "https://script.google.com/macros/s/AKfycbx96-TDxIvX8TTBUTMP7zBxgVetCbOSrum1bMjhu2cUsZbe-314n_VboWQyuOHscPqm/exec",
        {
          method: "post",
          body: formData,
        }
      )
        .then(function (response) {
          if (response.ok) {
            // Odpowiedź jest poprawna (status HTTP 200)
            return response.text(); // Pobierz zawartość odpowiedzi jako tekst
          } else {
            // Obsłuż błąd, np. wyświetl komunikat
            throw new Error("Błąd podczas wysyłania żądania.");
          }
        })
        .then(function (responseData) {
          // Tutaj możesz obsłużyć dane zwrotne z serwera
          console.log(responseData); // Wypisz odpowiedź w konsoli
        })
        .then(console.log(formData))
        .catch(function (error) {
          // Obsłuż błąd żądania
          console.error(error);
        });
    } else {
      this.setState({
        errors:{
          CustomerAge:false,
          CustomerPartnerAge:false,
          tel:false,
          email:false,
        }
      })
    }
  }


  formValidation = () =>{
    let CustomerAge = false;
    let CustomerPartnerAge = false;
    let tel = false;
    let email = false;
    let correct = false;

    // if(this.State){

    // }
  }
  
  render() {
    return (
      <div className="container ContactFormWrapper">
        <form
          className="Form col-md-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 "
          onSubmit={(e) => this.submit(e)}>
          <div
            class="accordion accordion-flush border border-warning border-top-0"
            id="accordionFlushExample">
            <div class="accordion-item">
              <h2 class="accordion-header" id="flush-headingOne">
                <button
                  class="accordion-button collapsed fw-bolder"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#flush-collapseOne"
                  aria-expanded="false"
                  aria-controls="flush-collapseOne">
                  Ubezpieczenie na życie - wypełnij formularz i wybierz
                  najlepszą ofertę
                </button>
              </h2>
              <div
                id="flush-collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="flush-headingOne"
                data-bs-parent="#accordionFlushExample">
                <div class="accordion-body">
                  <h3>
                    Poznajesz oferty, porównujesz i oszczędzasz nawet 34% przez
                    30 lat!
                  </h3>
                  <p>
                    Ubezpieczenie na życie może być tańsze bez utraty jakości.
                    Skorzystaj z naszego porównania, zamawiając oferty nawet od
                    4 Doradców. Dzięki porównaniu zyskasz pewność, że nie
                    przepłacasz.
                  </p>
                  <ol>
                    <li>Wypełniasz formularz w 2 minuty.</li>
                    <li>
                      Wybierasz najlepszy sposób przedstawienia oferty (np.
                      telefon, Skype, spotkanie).
                    </li>
                    <li>Wysłuchujesz ofert, skrojonych na twoją miarę.</li>
                    <li>Wyłaniasz najlepszą ofertę i kupujesz.</li>
                  </ol>
                  <p>
                    Dodatkowo rozszyfrujesz z ekspertem trudne zapisy Warunków
                    Ubezpieczenia. Poznając ofertę sprawdzisz też rzetelność
                    Doradcy, któremu powierzasz ochronę Twojej rodziny.
                    Zadecyduj z kim i jaką zawrzesz umowę. Ochroń swoją rodzinę
                    mądrze.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="form-check py-4">
            <input
              class="form-check-input"
              type="checkbox"
              id="Partner"
              name="Partner"
              onChange={this.handlePartner}
            />
            <div className="d-flex justify-content-between">
              <label class="form-check-label fw-bolder" for="Partner">
                Chce ubezpieczenie z partnerem.
              </label>
              <div>
                <p>
                  <button
                    class="btn "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseWidthExample"
                    aria-expanded="false"
                    aria-controls="collapseWidthExample">
                    ?
                  </button>
                </p>
                <div>
                  <div
                    class="collapse collapse-horizontal"
                    id="collapseWidthExample">
                    <div class="card card-body">
                      Jeśli polisa ma chronić was oboje, zaznacz Partnera.
                      Pytanie o ochronę dla dzieci znajdziesz w kolejnych
                      krokach.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <input
              type="number"
              class="form-control"
              id="CustomerAge"
              name="CustomerAge"
              required
            />
            <label for="CustomerAge">
              Ile Masz lat ?
                {this.state.errors.CustomerAge && <span>{this.messages.age_incorect}</span>}
            </label>
          </div>
          {this.state.partner ? (
            <div class="form-floating mb-3">
              <input
                type="number"
                class="form-control"
                id="CustomerPartnerAge"
                name="CustomerPartnerAge"
              />
              <label for="CustomerPartnerAge">Ile Masz partner(ka)
              {this.state.errors.CustomerPartnerAge && <span>{this.messages.age_incorect}</span>}
              
               </label>
            </div>
          ) : null}

          <select
            class="form-select form-select-lg mb-3"
            aria-label=".form-select example"
            name="Cel"
            id="Cel">
            <option selected>Jakie zdarzenia powinna chronić polisa?</option>
            <option value="Bliskich gdyby mnie zabrakło">
              Bliskich gdyby mnie zabrakło{" "}
            </option>
            <option value="Spłatę kredytu hipotecznego">
              Spłatę kredytu hipotecznego{" "}
            </option>
            <option value="Poważne zachorowania">Poważne zachorowania </option>
            <option value="Pobyt w szpitalu">Pobyt w szpitalu </option>
            <option value="Start dzieci w dorosłe życie">
              Start dzieci w dorosłe życie{" "}
            </option>
            <option value="Uzupełnienie emerytury z ZUS ">
              Uzupełnienie emerytury z ZUS{" "}
            </option>
          </select>

          <label for="customRange3" class="form-label fw-bolder">
            Wielkość polisy {this.state.rangeValue + " tyś"}
          </label>
          <input
            onChange={this.handleRangeChange}
            type="range"
            class="form-range"
            min="50000"
            max="500000"
            step="50000"
            name="Wartosc_polisy"
            id="Wartosc_polisy"
          />

          <div class="form-check py-4">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="Posiadam_dzieci"
              name="Posiadam_dzieci"
            />
            <div className="d-flex justify-content-between">
              <label
                class="form-check-label fw-bolder"
                for="flexCheckIndeterminate">
                Posiadam dzieci na utrzymaniu.
              </label>
              <div>
                <p>
                  <button
                    class="btn "
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseWidth2"
                    aria-expanded="false"
                    aria-controls="collapseWidth2">
                    ?
                  </button>
                </p>
                <div>
                  <div class="collapse collapse-horizontal" id="collapseWidth2">
                    <div class="card card-body">
                      Zaznacz tę opcję, jeśli pod twoją opieką znajduje się co
                      najmniej jedno dziecko do 18. r. ż.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-floating mb-3">
            <input id="Name" type="text" class="form-control" name="Name"  required/>
            <label for="name">Imię</label>
          </div>

          <div class="form-floating mb-3">
            <input id="Tel" type="tel" class="form-control" name="Tel"  required/>
            <label for="tel">Numer Telefonu 
            {this.state.errors.tel && <span>{this.messages.tel_Incorect}</span>}
            
            </label>
          </div>

          <div class="form-floating mb-3">
            <input id="Email" type="email" class="form-control" name="Email"  required/>
            <label for="email">E-mail 
            {this.state.errors.email && <span>{this.messages.email_Incorect}</span>}
            
            </label>
          </div>

          <div class="form-check rodo mb-4">
            <input
              class="form-check-input"
              type="checkbox"
              id="flexCheckDefault"
              required
            />
            <label class="form-check-label" for="flexCheckDefault">
              Wyrażam zgodę na przetwarzanie moich danych osobowych dla potrzeb
              niezbędnych do realizacji procesu rekrutacji zgodnie z
              Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 z
              dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w
              związku z przetwarzaniem danych osobowych i w sprawie swobodnego
              przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO).
            </label>
          </div>

          <Link to="/podsumowanie" class="thm-btn cta-three__btn"><input className="thm-btn cta-three__btn" type="submit" /></Link>
        </form>
      </div>
    );
  }
}
export default FormBuisnessComponent;
