import "./HomeComponentStyle.css"
import img from "../../../src/img/buisness.jpg"
import { Link } from 'react-router-dom';

function HomeComponent() {
  return (
        <div class="page-wrapper">
        <section class="cta-three">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6">
                        <Link to="/" class="cta-three__image">
                            <img src={img} alt="Awesome Image"/>
                        </Link>
                    </div>
                    <div class="col-lg-6">
                        <div class="cta-three__content">
                            <div class="block-title text-left">
                                <h2 class="block-title__title black-text-color">Indywidualna polisa na życie</h2>
                            </div>
                            <p class="cta-three__text">Indywidualna polisa na życie - 300.000 zł ochrony za 40,78 zł / miesiąc. Porównaj najlepsze oferty! </p>
                            <ul class="cta-three__list">
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Porównanie najlepszych ofert za 0 zł!</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Oferty ponad 10 towarzystw ubezpieczeniowych</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Szybki kontakt od naszego doradcy</li>
                                <li class="cta-three__list-item"><i class="fa fa-check"></i>Wybierz najlepszą ofertę bez wychodzenia z domu!</li>
                            </ul>
                            <Link to="/wybor" class="thm-btn cta-three__btn">Klikni i porównaj oferty</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="pricing-one" id="pricing">
            <div class="container">
                <div class="block-title text-center">
                    <h2 class="block-title__title black-text-color my-4">Dlaczego warto nam zaufać </h2>
                </div>
                <div class="row">
                    <div class="col-lg-4">
                        <div class="pricing-one__single">
                           
                            <span class="pricing-one__month">Jakość</span>
                            <ul class="pricing-one__list">
                                <li class="pricing-one__list-item">Działamy na podstawie Twoich zgód i </li>
                                <li class="pricing-one__list-item">dbamy, aby usługa była w pełni </li>
                                <li class="pricing-one__list-item">przejrzysta. Sprawdź nasz Regulamin</li>
                            </ul>
                           
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="pricing-one__single popular">
                        <span class="pricing-one__month">Bezpieczeństwo</span>
                            <ul class="pricing-one__list">
                                <li class="pricing-one__list-item">Połączenie z naszą witryną jest </li>
                                <li class="pricing-one__list-item">zabezpieczone certyfikatem SSL </li>
                            </ul>
                           
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="pricing-one__single">
                        <span class="pricing-one__month">Brak spamu</span>
                            <ul class="pricing-one__list">
                                <li class="pricing-one__list-item">Nie wysyłamy newsletterów.</li>
                                <li class="pricing-one__list-item">Nie wysyłamy spamu.</li>
                            </ul>
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    
  );
}

export default HomeComponent;
