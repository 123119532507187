import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import AppHeader from "./components/AppHeader/AppHeader";
import AppFooter from "./components/AppFooter/AppFooter";
import { BrowserRouter as Router, Routes, Route ,Link } from 'react-router-dom';
import ContactForm from './components/ContactForm';
import HomeComponent from './components/HomeComponent/HomeComponent';
import ThanYouPage from './components/ThanYouPage/ThanYouPage';
import FormChooseComponent from './components/FormChoooseComponent/FormChooseComponent';
import ForBuisnessComponent from './components/ForBuisnessComponent/ForBuisnessComponent';
import PropertyComponent from './components/PropertyComponent/PropertyComponent';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <div className='app'>
      <AppHeader/>
        <Router>
            <Routes>
                <Route path='/' Component={HomeComponent}/>
                <Route path='/indywidualne' Component={ContactForm}/>
                <Route path='/wybor' Component={FormChooseComponent}/>
                <Route path='/dlafirm' Component={ForBuisnessComponent}/>
                <Route path='/majatkowe' Component={PropertyComponent}/>
                <Route path='/podsumowanie' Component={ThanYouPage}/>
            </Routes>
        </Router>
      <AppFooter/>
    </div>
);