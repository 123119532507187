import "./ThanYouPage.css";


const ThanYouPage = () =>{
    return(
    <section class="pricing-one pricing-thank-you" id="pricing" >
    <div class="container">
        <div class="block-title text-center">
            <h2 class="block-title__title black-text-color my-4">Twoje zgłoszenie zostało wysłane!</h2>
        </div>
        <div class="row">
            <div class="offset-lg-2 col-lg-8">
                <div class="pricing-one__single popular">
                {/* <span class="pricing-one__month">Bezpieczeństwo</span> */}
                    <ul class="pricing-one__list">
                        <li class="pricing-one__list-item">Nasz specjalista skontaktuję się niebawem z tobą</li>
                        {/* <li class="pricing-one__list-item">zabezpieczone certyfikatem SSL </li> */}
                    </ul>
                   
                </div>
            </div>
        </div>
    </div>
</section>
    );
}

export default ThanYouPage;